import { lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.less'

const Dashboard = lazy(() => import('@/pages/Dashboard'))
const Cards = lazy(() => import('@/pages/Cards'))

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Cards />} />
        <Route path='/health-results' element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
